import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import { FiEdit } from "react-icons/fi";
import { GrNext } from "react-icons/gr";
import sideman from "../../../assets/images/man standing with bicycle.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import { AiFillCheckSquare, AiFillHome } from "react-icons/ai";
import { BsCheck2Circle, BsFillCheckCircleFill } from "react-icons/bs";
import { FaCircle, FaLocationArrow, FaWeight } from "react-icons/fa";
import { RiEdit2Line } from "react-icons/ri";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import Fade from "react-reveal/Flip";
import ContentLoader from "react-content-loader";
import { MdOutlineAddCircle } from "react-icons/md";
import { IoSquareOutline } from "react-icons/io5";
import {
    getAllCategory,
    getAllAnyStoreCourierCategory,
} from "../../../redux/mobile/item/action";
import {
    checkDeliveryAddress,
    checkPickupAddress,
    courierCheckOut,
} from "../../../redux/mobile/mobileOrder/action";
import { Link, Redirect } from "react-router-dom";
import map from "../../../assets/images/map.png";
import Footer from "../Footer";
import Shake from "react-reveal/Shake";
import {
    COURIER_PAYTM_PAYMENT_URL,
    COURIER_PROCESS_STATUS_UPDATE_RAZORPAY_URL,
    RAZORPAY_COURIER_URL,
} from "../../../api";
import cod from "../../../assets/images/cash-on-delivery 1.png";
import walletImg from "../../../assets/images/entypo_wallet.png";
import upi from "../../../assets/images/UPI1.jpg";
import Axios from "axios";
import { GET_ADDRESS_CITY_COURIER } from "../../../api/index";
import axios from "axios";
import ReactPlayer from "react-player";

export class Courier extends Component {
    state = {
        selectedItem: [],
        packagemenu: false,
        address: false,
        delivery: false,
        instruction: false,
        loading: false,
        delivery_address: [],
        pickup_address: [],
        nostore: false,
        nodelivery: false,
        noItemSelected: false,
        instructions: "",
        city_id: "",
        message_store: "",
        message_delivery: "",
        checkout: false,
        delivery_charge: "",
        courier_fee: "",
        total_charge: "",
        paymentSelected: false,
        payment_type: "",
        payment_sheet: false,
        pause_courier: 0,
    };

    CheckMenu = () => {
        this.setState({
            packagemenu: !this.state.packagemenu,
            noItemSelected: false,
        });
    };

    PickupAddress = () => {
        this.setState({ address: !this.state.address });
    };

    DeliveryAddress = () => {
        this.setState({ delivery: !this.state.delivery });
    };

    checkoutSheet = () => {
        this.setState({ checkout: !this.state.checkout });
    };

    Instructions = (e) => {
        if (e === "clear") {
            this.setState({ instructions: null });
            localStorage.removeItem("instructions");
        } else {
            this.setState({ instruction: !this.state.instruction });
        }
    };

    openPayment = () => {
        this.setState({ payment_sheet: true });
    };
    closePayment = () => {
        this.setState({ payment_sheet: false });
    };

    setOnline = () => {
        this.setState({
            payment_type: "ONLINE",
            paymentSelected: true,
            payment_sheet: false,
        });
        this.checkoutSheet();
    };

    setCod = () => {
        this.setState({
            payment_type: "COD",
            paymentSelected: true,
            payment_sheet: false,
        });
        this.checkoutSheet();
    };

    checkLocationPause() {
        const userAddress = JSON.parse(localStorage.getItem("userSetAddress"));
        const userLat = userAddress.latitude;
        const userLng = userAddress.longitude;

        Axios.post(GET_ADDRESS_CITY_COURIER, {
            lat: userLat,
            lng: userLng,
        }).then((response) => {
            ////// console.log(response);
            this.setState({ pause_courier: response?.data?.pause_courier });
        });
    }

    componentDidMount() {
        this.checkLocationPause();

        this.setState({ loading: true });
        this.props
            .getAllCategory(
                JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
                JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude
            )
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false });
                } else {
                    ////// console.log("API error");
                }
            });
        this.props.getAllAnyStoreCourierCategory().then((response) => {
            if (response && response.payload && response.payload.success) {
                ////// console.log('success')
            } else {
                ////// console.log("API error");
            }
        });
        let deliveryAddress = JSON.parse(
            localStorage.getItem("deliveryAddress")
        );
        this.setState({ delivery_address: deliveryAddress });
        let pickAddress = JSON.parse(localStorage.getItem("pickUpAddress"));
        this.setState({ pickup_address: pickAddress });
        let instructions = localStorage.getItem("instructions");
        this.setState({ instructions: instructions });
        let packageItems = JSON.parse(localStorage.getItem("packageItems"));
        this.setState({ selectedItem: packageItems });

        if (pickAddress) {
            this.__checkPickupAddress(pickAddress, deliveryAddress);
        } else {
            this.setState({ loading: false });
        }

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(script);
    }

    __checkPickupAddress = (pickAddress, deliveryAddress) => {
        this.props
            .checkPickupAddress(pickAddress.latitude, pickAddress.longitude)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, message_store: null });
                    if (pickAddress && deliveryAddress) {
                        this.__checkDeliveryAddress(
                            deliveryAddress,
                            response.payload.city
                        );
                    } else {
                        this.setState({ loading: false });
                    }
                } else {
                    this.setState({
                        loading: false,
                        message_store: response.payload.message,
                    });
                }
            });
    };

    __checkDeliveryAddress = (deliveryAddress, city_id) => {
        if (city_id) {
            this.props
                .checkDeliveryAddress(
                    deliveryAddress.latitude,
                    deliveryAddress.longitude,
                    city_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        let totalcharge = parseFloat(
                            parseFloat(response.payload.delivery_charge) +
                                parseFloat(response.payload.courier_fee)
                        )?.toFixed(2);
                        this.setState({
                            loading: false,
                            city_id: city_id,
                            delivery_charge: response.payload.delivery_charge,
                            courier_fee: response.payload.courier_fee,
                            total_charge: totalcharge,
                            message_delivery: null,
                        });
                    } else {
                        ////// console.log(response.payload.message)
                        this.setState({
                            loading: false,
                            message_delivery: response.payload.message,
                        });
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    };

    selectedItem = (item, index) => {
        let data = this.state.selectedItem ? this.state.selectedItem : [];
        if (data.length === 0) {
            data.push(item);
            this.setState({ selectedItem: data, noItemSelected: false });
            localStorage.setItem("packageItems", JSON.stringify(data));
        } else {
            if (data.find((cp) => cp.id === item.id) === undefined) {
                data.push(item);
                this.setState({ selectedItem: data, noItemSelected: false });
                localStorage.setItem("packageItems", JSON.stringify(data));
            } else {
                data.splice(
                    data.findIndex((x) => x.id === item.id),
                    1
                );
                this.setState({ selectedItem: data, noItemSelected: false });
                localStorage.setItem("packageItems", JSON.stringify(data));
            }
        }
    };

    grabDeliveryLocation = () => {
        localStorage.setItem("LocationFrom", "COURIER");
        localStorage.setItem("BackTo", "DELIVERY");
        this.props.history.push("/set-location");
    };

    grabPickupLocation = () => {
        localStorage.setItem("LocationFrom", "COURIER");
        localStorage.setItem("BackTo", "PICKUP");
        this.props.history.push("/set-location");
    };

    NotSelected = (type) => {
        if (type === "pickupAddress") {
            this.setState({ nostore: false });
            setTimeout(() => {
                this.setState({ nostore: true });
            }, 1);
        } else if (type === "delivery_address") {
            this.setState({ nodelivery: false });
            setTimeout(() => {
                this.setState({ nodelivery: true });
            }, 1);
        } else if (type === "selectedItem") {
            this.setState({ noItemSelected: false });
            setTimeout(() => {
                this.setState({ noItemSelected: true });
            }, 100);
        }
    };

    handleInstruction = (e) => {
        e.preventDefault();
        let value = this.state.instructions;
        if (value) {
            localStorage.setItem("instructions", value);
        } else {
            localStorage.removeItem("instructions");
        }
        this.setState({ instruction: false });
    };

    sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
        axios
            .post(COURIER_PROCESS_STATUS_UPDATE_RAZORPAY_URL, {
                id: id,
                token: this.props.user.data.auth_token,
                payment_id,
                order_id,
                signature,
            })
            .then((response) => {
                localStorage.removeItem("deliveryAddress");
                localStorage.removeItem("pickUpAddress");
                localStorage.removeItem("instructions");
                localStorage.removeItem("packageItems");
                this.setState({
                    loading: false,
                    selectedItem: null,
                    pickup_address: null,
                    delivery_address: null,
                    instructions: null,
                });
                // if (response?.data?.success) {
                this.props.history.push(
                    "/courier-success/" + response?.data?.data?.id
                );

                // }
            });
    };

    successRazor = () => {
        this.setState({ order_succes: true, order_confirm: false });

        //// console.log('====================================');
        //// console.log("successRazor");
        //// console.log('====================================');
        // setTimeout(() => {
        // 	this.context.router.history.push("/my-orders");
        // }, 5000);
    };

    launchRazor = (order, history, runFunction, successRazor) => {
        axios
            .post(RAZORPAY_COURIER_URL, {
                amount: parseFloat(this.state.total_charge).toFixed(2),
                id: order.id,
                token: this.props.user.data.auth_token,
            })
            .then((res) => {
                // //// console.log(res.data.response.id);
                if (res.data.razorpay_success) {
                    const options = {
                        key: "rzp_live_zrh9wS763Pwi9V",
                        amount: parseFloat(this.state.total_charge).toFixed(2),
                        webview_intent: true,
                        name: "Grosav",
                        currency: "INR",
                        order_id: res.data.response.id,
                        payment_capture: 1,
                        method: {
                            netbanking: true,
                            card: true,
                            wallet: false,
                            upi: true,
                        },

                        handler(response) {
                            //// console.log("Final Response", response);
                            runFunction(
                                order.id,
                                response.razorpay_payment_id,
                                response.razorpay_order_id,
                                response.razorpay_signature
                            );

                            successRazor();
                        },
                        modal: {
                            ondismiss: function () {
                                //// console.log("closed");
                                runFunction(order.id, null, null, null);
                            },
                            confirm_close: true,
                        },
                        prefill: {
                            name: this.props.user.data.name,
                            contact: this.props.user.data.phone,
                            email: this.props.user.data.email
                                ? this.props.user.data.email
                                : "",
                        },
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.open();
                }
            });
    };
    CheckOut = (e) => {
        const { user } = this.props;
        e.preventDefault();
        const {
            selectedItem,
            delivery_address,
            pickup_address,
            instructions,
            city_id,
            message_store,
            message_delivery,
            total_charge,
            delivery_charge,
            courier_fee,
        } = this.state;
        this.setState({
            loading: true,
            checkout: false,
        });
        // user.data.auth_token, city_id, storeAddress, deliveryAddress, categorySelected, items
        if (pickup_address && delivery_address && selectedItem) {
            this.props
                .courierCheckOut(
                    user.data.auth_token,
                    city_id,
                    pickup_address,
                    delivery_address,
                    selectedItem,
                    instructions,
                    total_charge,
                    delivery_charge,
                    this.state.payment_type,
                    courier_fee
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        // //// console.log(response.payload);
                        if (
                            response.payload.courier.payment_mode === "ONLINE"
                        ) {
                            // window.location = COURIER_PAYTM_PAYMENT_URL + "/" + response.payload.courier.id;
                            // window.location = RAZORPAY_COURIER_URL + "/" + response.payload.courier.id + "/2"; // 2 : Normal Order
                            this.launchRazor(
                                response.payload.courier,
                                this.props.history,
                                this.sendOrderCompleteResponse,
                                this.successRazor
                            );
                        } else {
                            localStorage.removeItem("deliveryAddress");
                            localStorage.removeItem("pickUpAddress");
                            localStorage.removeItem("instructions");
                            localStorage.removeItem("packageItems");
                            this.setState({
                                loading: false,
                                selectedItem: null,
                                pickup_address: null,
                                delivery_address: null,
                                instructions: null,
                            });
                            setTimeout(() => {
                                this.props.history.push(
                                    "/courier-success/" +
                                        response.payload.courier.id
                                );
                            }, 2000);
                        }
                    } else {
                        this.setState({ loading: false });
                        this.props.history.push(
                            "/courier-success/" + response.payload.courier.id
                        );
                        localStorage.setItem(
                            "courierErrorMessage",
                            response.payload.message
                        );
                    }
                });
        } else {
            if (message_store) {
                this.message_store(message_store);
                ////// console.log("message_store")
            } else if (message_delivery) {
                ////// console.log("message_delivery")
                this.message_delivery(message_delivery);
            } else if (pickup_address == null) {
                ////// console.log("pickup_address")
                this.NotSelected("pickupAddress");
            } else if (delivery_address == null) {
                ////// console.log("delivery_address")
                this.NotSelected("delivery_address");
            } else if (selectedItem == null) {
                ////// console.log("selectedItem")
                this.NotSelected("selectedItem");
            }

            this.setState({ loading: false });
        }
    };

    message_store = (message_store) => {
        localStorage.removeItem("deliveryAddress");
        this.setState({ message_store: null });
        setTimeout(() => {
            this.setState({ message_store: message_store });
        }, 100);
    };

    message_delivery = (message_delivery) => {
        this.setState({ message_delivery: null });
        setTimeout(() => {
            this.setState({ message_delivery: message_delivery });
        }, 100);
    };

    getHome = (e) => {
        e.preventDefault();
        this.props.history.replace("/home");
    };

    getState = (aa, ab, ac, ad) => {
        let state = true;
        if (aa) {
            state = false;
        } else if (ab) {
            state = false;
        } else if (ac) {
            state = false;
        } else if (ad) {
            state = false;
        }
        // ////// console.log(state)
        return state;
    };
    render() {
        const { user, category, couriercategory } = this.props;
        const {
            selectedItem,
            delivery_address,
            pickup_address,
            nostore,
            nodelivery,
            instructions,
            message_delivery,
            message_store,
            noItemSelected,
            courier_fee,
            delivery_charge,
            total_charge,
            payment_type,
            paymentSelected,
            payment_sheet,
        } = this.state;
        if (!user.success) {
            return <Redirect to={"/signup"} />;
        }
        {console.log(category,"category")}
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="12"
                                y="20"
                                rx="10"
                                ry="10"
                                width="32"
                                height="27"
                            />
                            <rect
                                x="85"
                                y="25"
                                rx="10"
                                ry="10"
                                width="100"
                                height="20"
                            />
                            <rect
                                x="230"
                                y="20"
                                rx="10"
                                ry="10"
                                width="32"
                                height="27"
                            />
                            <rect
                                x="10"
                                y="79"
                                rx="10"
                                ry="10"
                                width="255"
                                height="80"
                            />
                            <rect
                                x="10"
                                y="175"
                                rx="10"
                                ry="10"
                                width="259"
                                height="160"
                            />
                            <rect
                                x="10"
                                y="530"
                                rx="11"
                                ry="11"
                                width="259"
                                height="33"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {couriercategory && couriercategory?.length > 0 ? (
                            <React.Fragment>
                                {/* <div onClick={(e) => this.getHome(e)}> */}
                                <MobileBackButtonWithCenterTitle
                                    title="Courier"
                                    wayToHome={true}
                                />
                                {/* </div> */}
                                <div
                                    className="section-div"
                                    style={
                                        this.state.pause_courier === 1
                                            ? {
                                                  pointerEvents: "none",
                                                  filter: "grayscale(1)",
                                                  overflow: "hidden",
                                                  height: "88vh",
                                              }
                                            : {}
                                    }
                                >
                                    <div
                                        className="px-4 pt-3 "
                                        style={{ paddingBottom: "55vw" }}
                                    >
                                        <div>
                                            {/* <div className="pickup-div d-flex flex-column px-3 py-3"
                                                style={{ backgroundColor: "#FFF9ED", borderRadius: "14px", }} >
                                                <div className="d-flex flex-row align-items-center">
                                                    <FaWeight style={{ color: "#F9A84D", fontSize: "1.1rem", }} />
                                                    <span className="px-1" style={{ fontSize: "1.2em", fontWeight: "700", color: "#FF9012", }} >
                                                        Watch the weight
                                                    </span>
                                                </div>
                                                <span className="mt-2" style={{ fontSize: "1em" }} >
                                                    Please ensure that the package can be carried on a bike
                                                </span>
                                            </div> */}
                                               <div className=" d-flex flex-column  py-3"
                                                style={{ borderRadius: "14px", }} >
                                                     <ReactPlayer
                                                url={category?.Courier?.value}
                                                playing={false}  
                                                controls={true}
                                                width="100%"
                                                height="200px" // Adjust height as needed
                                                style={{
                                                    borderRadius: "10px",
                                                    overflow: "hidden",
                                                }} // Add border radius
                                            />
                                                
                                            </div> 


                                            
                                            <div
                                                className="d-flex flex-column"
                                                style={{
                                                    backgroundColor: "#ffffff",
                                                }}
                                            >
                                                <div
                                                    className="position-relative py-1"
                                                    style={{
                                                        height: "290px",
                                                        borderRadius: "40px",
                                                    }}
                                                >
                                                    <div className="d-flex flex-column py-3">
                                                        {/* pickup address */}
                                                        <div
                                                            className="d-flex flex-row  mb-4"
                                                            style={{
                                                                paddingLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    zIndex: "2",
                                                                }}
                                                            >
                                                                <div
                                                                    className="p-1"
                                                                    style={{
                                                                        zIndex: "2",
                                                                        color: "#8399A9",
                                                                        backgroundColor:
                                                                            "#fff",
                                                                        borderRadius:
                                                                            "50%",
                                                                    }}
                                                                >
                                                                    {pickup_address &&
                                                                    pickup_address.address ? (
                                                                        <BsFillCheckCircleFill
                                                                            style={{
                                                                                color: "#ff9012",
                                                                                fontSize:
                                                                                    "1.2rem",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <FaCircle
                                                                            style={{
                                                                                color: "#DADADA",
                                                                                fontSize:
                                                                                    "1.2rem",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2"
                                                                onClick={() =>
                                                                    this.PickupAddress()
                                                                }
                                                            >
                                                                <div className="d-flex flex-column py-1">
                                                                    <div className=" fw-bolder text-muted fs-6">
                                                                        Pickup
                                                                        Address
                                                                    </div>

                                                                    <div className="text-muted pe-2">
                                                                        {pickup_address
                                                                            ? pickup_address.house !==
                                                                                  "" &&
                                                                              pickup_address.house !==
                                                                                  pickup_address.address
                                                                                ? pickup_address.house +
                                                                                  "," +
                                                                                  pickup_address.landmark +
                                                                                  "," +
                                                                                  pickup_address.address
                                                                                : pickup_address.address
                                                                            : "Select pickup location"}
                                                                    </div>
                                                                    {nostore && (
                                                                        <Shake
                                                                            Shake
                                                                            duration={
                                                                                250
                                                                            }
                                                                            right
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                Need
                                                                                to
                                                                                select
                                                                                Pickup
                                                                                Address
                                                                            </span>
                                                                        </Shake>
                                                                    )}
                                                                    {message_store && (
                                                                        <Shake
                                                                            Shake
                                                                            duration={
                                                                                250
                                                                            }
                                                                            right
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    message_store
                                                                                }
                                                                            </div>
                                                                        </Shake>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {pickup_address &&
                                                                    pickup_address?.address ? (
                                                                        <FiEdit
                                                                            size={
                                                                                "1.4em"
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <GrNext />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* delivery address */}
                                                        <div
                                                            className="d-flex flex-row mb-4"
                                                            style={{
                                                                paddingLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    zIndex: "2",
                                                                }}
                                                            >
                                                                <div
                                                                    className="p-1"
                                                                    style={{
                                                                        zIndex: "2",
                                                                        color: "#8399A9",
                                                                        backgroundColor:
                                                                            "#fff",
                                                                        borderRadius:
                                                                            "50%",
                                                                    }}
                                                                >
                                                                    {delivery_address &&
                                                                    delivery_address.address ? (
                                                                        <BsFillCheckCircleFill
                                                                            style={{
                                                                                color: "#ff9012",
                                                                                fontSize:
                                                                                    "1.2rem",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <FaCircle
                                                                            style={{
                                                                                color: "#DADADA",
                                                                                fontSize:
                                                                                    "1.2rem",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2"
                                                                onClick={
                                                                    message_store
                                                                        ? () =>
                                                                              this.message_store(
                                                                                  message_store
                                                                              )
                                                                        : pickup_address
                                                                        ? () =>
                                                                              this.DeliveryAddress()
                                                                        : () =>
                                                                              this.NotSelected(
                                                                                  "pickupAddress"
                                                                              )
                                                                }
                                                            >
                                                                <div className="d-flex flex-column py-1">
                                                                    <div className=" fw-bolder text-muted fs-6">
                                                                        Delivery
                                                                        Address
                                                                    </div>
                                                                    <div className="text-muted pe-2">
                                                                        {delivery_address
                                                                            ? delivery_address.house !==
                                                                                  "" &&
                                                                              delivery_address.house !==
                                                                                  delivery_address.address
                                                                                ? delivery_address.house +
                                                                                  "," +
                                                                                  delivery_address.landmark +
                                                                                  "," +
                                                                                  delivery_address.address
                                                                                : delivery_address.address
                                                                            : "Select delivery location"}
                                                                    </div>
                                                                    {nodelivery && (
                                                                        <Shake
                                                                            Shake
                                                                            duration={
                                                                                250
                                                                            }
                                                                            right
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                Need
                                                                                to
                                                                                select
                                                                                Delivery
                                                                                Address
                                                                            </span>
                                                                        </Shake>
                                                                    )}
                                                                    {message_delivery && (
                                                                        <Shake
                                                                            Shake
                                                                            duration={
                                                                                250
                                                                            }
                                                                            right
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    message_delivery
                                                                                }
                                                                            </div>
                                                                        </Shake>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {delivery_address &&
                                                                    delivery_address.address ? (
                                                                        <FiEdit
                                                                            size={
                                                                                "1.4em"
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <GrNext />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* package content */}
                                                        <div
                                                            className="d-flex flex-row mb-4"
                                                            style={{
                                                                paddingLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <div
                                                                className="position-relative"
                                                                style={{
                                                                    zIndex: "2",
                                                                }}
                                                            >
                                                                <div
                                                                    className="p-1"
                                                                    style={{
                                                                        zIndex: "2",
                                                                        color: "#8399A9",
                                                                        backgroundColor:
                                                                            "#fff",
                                                                        borderRadius:
                                                                            "50%",
                                                                    }}
                                                                >
                                                                    {selectedItem &&
                                                                    selectedItem.length >
                                                                        0 ? (
                                                                        <BsFillCheckCircleFill
                                                                            style={{
                                                                                color: "#ff9012",
                                                                                fontSize:
                                                                                    "1.2rem",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <FaCircle
                                                                            style={{
                                                                                color: "#DADADA",
                                                                                fontSize:
                                                                                    "1.2rem",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="position-absolute px-2">
                                                                    <div
                                                                        style={{
                                                                            borderLeft:
                                                                                "10px solid #fff",
                                                                            height: "150px",
                                                                            zIndex: "1",
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2"
                                                                onClick={
                                                                    message_store
                                                                        ? () =>
                                                                              this.message_store(
                                                                                  message_store
                                                                              )
                                                                        : message_delivery
                                                                        ? () =>
                                                                              this.message_delivery(
                                                                                  message_delivery
                                                                              )
                                                                        : pickup_address
                                                                        ? delivery_address
                                                                            ? () =>
                                                                                  this.CheckMenu()
                                                                            : () =>
                                                                                  this.NotSelected(
                                                                                      "delivery_address"
                                                                                  )
                                                                        : () =>
                                                                              this.NotSelected(
                                                                                  "pickupAddress"
                                                                              )
                                                                }
                                                            >
                                                                <div className="d-flex flex-column py-1">
                                                                    <div className=" fw-bolder text-muted fs-6">
                                                                        Select
                                                                        Package
                                                                        Content
                                                                    </div>
                                                                    <div className="text-muted">
                                                                        {selectedItem &&
                                                                        selectedItem.length >
                                                                            0 ? (
                                                                            selectedItem?.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => (
                                                                                    <span>
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                        {index ===
                                                                                        selectedItem.length -
                                                                                            1
                                                                                            ? ""
                                                                                            : ", "}
                                                                                    </span>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            <span>
                                                                                Example
                                                                                :
                                                                                food
                                                                                ,content.
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    {noItemSelected && (
                                                                        <Shake
                                                                            Shake
                                                                            duration={
                                                                                250
                                                                            }
                                                                            right
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                Need
                                                                                to
                                                                                Select
                                                                                Package
                                                                                Content
                                                                            </span>
                                                                        </Shake>
                                                                    )}
                                                                </div>
                                                                <div className="">
                                                                    {selectedItem &&
                                                                    selectedItem.length >
                                                                        0 ? (
                                                                        <FiEdit
                                                                            size={
                                                                                "1.4em"
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <GrNext />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* any instructions */}
                                                        <div
                                                            className="d-flex px-2 py-1 mt-3 pb-5"
                                                            style={{
                                                                zIndex: "2",
                                                            }}
                                                        >
                                                            <div
                                                                className="d-flex flex-row align-items-center px-2 py-1"
                                                                style={{
                                                                    backgroundColor:
                                                                        "#F3F3F3",
                                                                    borderRadius:
                                                                        "14px",
                                                                }}
                                                                onClick={() =>
                                                                    this.Instructions()
                                                                }
                                                            >
                                                                <RiEdit2Line
                                                                    style={{
                                                                        fontSize:
                                                                            "1.5rem",
                                                                        color: "#F9A84D",
                                                                        zIndex: "2",
                                                                    }}
                                                                />
                                                                <div
                                                                    className="instruction ms-2"
                                                                    style={{
                                                                        fontSize:
                                                                            "15px",
                                                                        color: "black",
                                                                        zIndex: "2",
                                                                    }}
                                                                >
                                                                    Any
                                                                    instructions?
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="position-absolute px-4">
                                                            <div
                                                                style={{
                                                                    borderLeft:
                                                                        "1px dashed #000",
                                                                    height: "300px",
                                                                    zIndex: "-99",
                                                                }}
                                                            ></div>
                                                        </div>
                                                        {this.state
                                                            .pause_courier ===
                                                            1 && (
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    background:
                                                                        "black",
                                                                    color: "white",
                                                                    position:
                                                                        "fixed",
                                                                    width: "100%",
                                                                    bottom: "30px",
                                                                    padding:
                                                                        "10px 0px",
                                                                    zIndex: 11111,
                                                                    margin: "0 auto",
                                                                    left: 0,
                                                                    right: 0,
                                                                }}
                                                            >
                                                                <span>
                                                                    Service is
                                                                    off duty
                                                                    heavy rush
                                                                    it will
                                                                    resume soon
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {this.state.instruction || this.state.delivery || this.state.address || this.state.packagemenu ? "" : */}
                                            <Fade
                                                collapse
                                                bottom
                                                when={this.getState(
                                                    this.state.instruction,
                                                    this.state.delivery,
                                                    this.state.address,
                                                    this.state.packagemenu
                                                )}
                                            >
                                                <div
                                                    className="pickup-btn px-3 py-3 position-fixed w-100"
                                                    style={{
                                                        bottom: "3vw",
                                                        left: "0",
                                                        zIndex: "999",
                                                    }}
                                                >
                                                    <div className="position-relative">
                                                        <div
                                                            className="position-absolute"
                                                            style={{
                                                                bottom: "-18px",
                                                                right: "-15px",
                                                            }}
                                                        >
                                                            <img
                                                                src={sideman}
                                                                style={{
                                                                    width: "35vw",
                                                                }}
                                                                className="sideman"
                                                                alt="sideman"
                                                            />
                                                        </div>
                                                    </div>
                                                    {selectedItem &&
                                                    delivery_address &&
                                                    pickup_address ? (
                                                        <>
                                                            {user &&
                                                            user.success ? (
                                                                <button
                                                                    type="button"
                                                                    className="pickup-button btn btn py-2 w-100"
                                                                    onClick={() =>
                                                                        this.openPayment()
                                                                    }
                                                                >
                                                                    <b
                                                                        style={{
                                                                            color: "white",
                                                                        }}
                                                                    >
                                                                        Checkout
                                                                    </b>
                                                                </button>
                                                            ) : (
                                                                <Link
                                                                    to="/signup"
                                                                    className="text-dark"
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        className="pickup-button btn btn py-2 w-100"
                                                                    >
                                                                        <b
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        >
                                                                            Checkout
                                                                        </b>
                                                                    </button>
                                                                </Link>
                                                            )}
                                                        </>
                                                    ) : pickup_address &&
                                                      delivery_address ? (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="pickup-button btn btn py-2 w-100"
                                                                onClick={() =>
                                                                    this.NotSelected(
                                                                        "selectedItem"
                                                                    )
                                                                }
                                                            >
                                                                <b
                                                                    style={{
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    Checkout
                                                                </b>
                                                            </button>
                                                        </>
                                                    ) : pickup_address ? (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="pickup-button btn btn py-2 w-100"
                                                                onClick={() =>
                                                                    this.NotSelected(
                                                                        "delivery_address"
                                                                    )
                                                                }
                                                            >
                                                                <b
                                                                    style={{
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    Checkout
                                                                </b>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="pickup-button btn btn py-2 w-100"
                                                                onClick={() =>
                                                                    this.NotSelected(
                                                                        "pickupAddress"
                                                                    )
                                                                }
                                                            >
                                                                <b
                                                                    style={{
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    Checkout
                                                                </b>
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </Fade>
                                            {/* } */}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <MobileBackButtonWithCenterTitle
                                    title="Courier"
                                    route="home"
                                    wayToHome={true}
                                />
                                <div
                                    className="d-flex flex-column align-items-center"
                                    style={{ paddingTop: "15vw" }}
                                >
                                    <div className="pt-5 pt-3 d-flex flex-column align-items-center">
                                        <img
                                            src={map}
                                            alt="no items"
                                            style={{
                                                objectFit: "cover",
                                                width: "70%",
                                            }}
                                        />
                                    </div>
                                    <span className="pt-4 fw-bolder fs-6">
                                        Sorry!
                                    </span>
                                    <span className="mt-1 fw-bolder fs-6">
                                        We don't deliver to your location.
                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                        {/* package content */}
                        <BottomSheet
                            open={this.state.packagemenu}
                            onDismiss={() => this.CheckMenu()}
                        >
                            <div className="text-center mt-2 ">
                                <span
                                    style={{
                                        fontSize: "19px",
                                        color: "#FF9012",
                                    }}
                                >
                                    <b>select package content</b>
                                </span>
                            </div>
                            <div className="mt-3">
                                {couriercategory &&
                                    couriercategory?.length > 0 &&
                                    couriercategory?.map((item, index) => (
                                        <div className="px-3 pb-4 w-100">
                                            <span
                                                className="form-check"
                                                onClick={(e) => {
                                                    this.selectedItem(
                                                        item,
                                                        index
                                                    );
                                                }}
                                            >
                                                {selectedItem?.find(
                                                    (cp) => cp.id === item.id
                                                ) !== undefined ? (
                                                    <AiFillCheckSquare
                                                        className="me-2"
                                                        style={{
                                                            color: "orange",
                                                            fontSize: "1.5rem",
                                                        }}
                                                    />
                                                ) : (
                                                    <IoSquareOutline
                                                        className="me-2"
                                                        style={{
                                                            fontSize: "1.5rem",
                                                            color: "#c3c3c3",
                                                        }}
                                                    />
                                                )}
                                                <span
                                                    style={{ color: "black" }}
                                                >
                                                    {item.name}
                                                </span>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                            <div
                                className="d-flex flex-row align-items-center justify-content-between gap-2 py-3 px-3"
                                style={{
                                    backgrounColor: "red",
                                    border: "1px 0px 0px 0px solid #aaa",
                                }}
                            >
                                <button
                                    onClick={() => {
                                        this.setState({ selectedItem: [] });
                                    }}
                                    className="anystr-cancel-button py-3 w-100"
                                >
                                    <b style={{ color: "#FF9012" }}>Cancel</b>
                                </button>
                                <button
                                    onClick={() => this.CheckMenu()}
                                    className="anystr-done-button py-3 w-100"
                                >
                                    <b style={{ color: "white" }}>Done</b>
                                </button>
                            </div>
                        </BottomSheet>
                        <BottomSheet
                            open={this.state.payment_sheet}
                            onDismiss={() => this.closePayment()}
                        >
                            <div className="d-flex flex-column justify-content-center px-3 pb-3">
                                <div className="pt-3">
                                    <p
                                        style={{
                                            color: "#7b7b7b",
                                            fontSize: "1.2em",
                                            fontWeight: "650",
                                        }}
                                    >
                                        Choose Payment Method
                                    </p>
                                </div>
                                <div
                                    className="mobile-card2 py-3 w-100 mt-3 d-flex flex-row justify-content-center align-items-center"
                                    onClick={() => this.setCod()}
                                    style={{ border: "1px solid #fea24b" }}
                                >
                                    <img src={cod} className="cod" alt="cod" />
                                    <span style={{ fontWeight: "800" }}>
                                        Cash on delivery
                                    </span>
                                </div>

                                <div
                                    className="mobile-card2 py-3 w-100  d-flex flex-column justify-content-center align-items-center mt-2"
                                    onClick={() => this.setOnline()}
                                    style={{ border: "1px solid #fea24b" }}
                                >
                                    <img src={upi} className="upi" alt="upi" />
                                </div>
                            </div>
                        </BottomSheet>

                        {/* pickup address */}
                        <BottomSheet
                            open={this.state.address}
                            onDismiss={() => this.PickupAddress()}
                        >
                            <div className="px-3">
                                <div className=" mt-2 ">
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "700",
                                        }}
                                    >
                                        Choose PickUp Address
                                    </span>
                                </div>

                                {pickup_address && pickup_address.address ? (
                                    <>
                                        <div className="d-flex flex-row align-items-center py-3 px-3 mt-3">
                                            <div
                                                onClick={
                                                    this.grabPickupLocation
                                                }
                                            >
                                                <MdOutlineAddCircle
                                                    style={{
                                                        color: "#F9A84D",
                                                        backgroundColor:
                                                            "white",
                                                        fontSize: "1.5rem",
                                                    }}
                                                />
                                                <span
                                                    className="px-3"
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        color: "#F9A84D",
                                                    }}
                                                >
                                                    Change PickUp Address
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div
                                            className="d-flex flex-row align-items-center justify-content-between py-2 px-3 "
                                            onClick={this.setPickupAddress}
                                        >
                                            <div>
                                                <FaLocationArrow
                                                    style={{
                                                        color: "#f97b00",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="ps-4 px-2"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {pickup_address?.house
                                                    ? pickup_address?.house +
                                                      ", " +
                                                      pickup_address?.address
                                                    : pickup_address?.address}
                                            </div>
                                            <div>
                                                {pickup_address &&
                                                pickup_address?.address ? (
                                                    <BsCheck2Circle
                                                        style={{
                                                            color: "DA6317",
                                                            fontSize: "1.5rem",
                                                        }}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-row align-items-center  py-3 px-4 mt-3">
                                        <div onClick={this.grabPickupLocation}>
                                            <MdOutlineAddCircle
                                                style={{
                                                    color: "#F9A84D",
                                                    backgroundColor: "white",
                                                    fontSize: "1.5rem",
                                                }}
                                            />
                                            <span
                                                className="px-3"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    color: "#F9A84D",
                                                }}
                                            >
                                                Add PickUp Address
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <hr />
                                <div className="px-3  pb-4 w-100">
                                    <button
                                        className="select-button btn btn py-2 w-100"
                                        type="button"
                                        onClick={() => this.PickupAddress()}
                                    >
                                        <b style={{ color: "white" }}>Close</b>
                                    </button>
                                </div>
                            </div>
                        </BottomSheet>

                        {/* delivery address */}
                        <BottomSheet
                            open={this.state.delivery}
                            onDismiss={() => this.DeliveryAddress()}
                        >
                            <div className="px-3">
                                <div className=" mt-2">
                                    <span
                                        style={{
                                            fontSize: "1.5em",
                                            fontWeight: "700",
                                        }}
                                    >
                                        Delivery Address
                                    </span>
                                </div>

                                {delivery_address &&
                                delivery_address?.address ? (
                                    <>
                                        <div className="d-flex flex-row align-items-center  py-2 px-4 mt-3">
                                            <div
                                                onClick={
                                                    this.grabDeliveryLocation
                                                }
                                            >
                                                <MdOutlineAddCircle
                                                    style={{
                                                        color: "#F9A84D",
                                                        backgroundColor: "",
                                                        fontSize: "1.5rem",
                                                    }}
                                                />
                                                <span
                                                    className="px-3"
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        color: "#F9A84D",
                                                    }}
                                                >
                                                    Change Delivery Address
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-row align-items-center justify-content-between py-2 px-4 ">
                                            <div>
                                                <FaLocationArrow
                                                    style={{
                                                        color: "#f97b00",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="ps-4 px-3"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {delivery_address?.house
                                                    ? delivery_address?.house +
                                                      ", " +
                                                      delivery_address?.address
                                                    : delivery_address?.address}
                                            </div>

                                            <div>
                                                {delivery_address &&
                                                delivery_address?.address ? (
                                                    <BsCheck2Circle
                                                        style={{
                                                            color: "DA6317",
                                                            fontSize: "1.5rem",
                                                        }}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-row align-items-center  py-2 px-4 mt-3">
                                        <div
                                            onClick={this.grabDeliveryLocation}
                                        >
                                            <MdOutlineAddCircle
                                                style={{
                                                    color: "#F9A84D",
                                                    backgroundColor: "",
                                                    fontSize: "1.5rem",
                                                }}
                                            />
                                            <span
                                                className="px-3"
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    color: "#F9A84D",
                                                }}
                                            >
                                                Add Delivery Address
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <hr />
                                <div className=" px-3  pb-4 w-100">
                                    <button
                                        type="button"
                                        className="select-button btn btn py-2 w-100"
                                        onClick={() => this.DeliveryAddress()}
                                    >
                                        <b style={{ color: "white" }}>Close</b>
                                    </button>
                                </div>
                            </div>
                        </BottomSheet>

                        {/* instructions */}
                        <BottomSheet
                            open={this.state.instruction}
                            onDismiss={() => this.Instructions()}
                        >
                            <div className="px-3 py-3">
                                <Form
                                    onSubmit={(e) => this.handleInstruction(e)}
                                >
                                    <InputGroup
                                        className="mt-3 coupon-input"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <FormControl
                                            as="textarea"
                                            rows={5}
                                            value={
                                                instructions ? instructions : ""
                                            }
                                            placeholder="Any instructions"
                                            className="input-coupon"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.setState({
                                                    instructions: value,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-between gap-2 py-3 px-3"
                                        style={{
                                            backgrounColor: "red",
                                            border: "1px 0px 0px 0px solid #aaa",
                                        }}
                                    >
                                        {instructions &&
                                        instructions.length > 0 ? (
                                            <div
                                                onClick={() =>
                                                    this.Instructions("clear")
                                                }
                                                className="anystr-cancel-button text-center cour-btn w-100"
                                            >
                                                <b style={{ color: "#FF9012" }}>
                                                    Clear
                                                </b>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    this.Instructions("cancel")
                                                }
                                                className="anystr-cancel-button cour-btn w-100"
                                            >
                                                <b style={{ color: "#FF9012" }}>
                                                    Cancel
                                                </b>
                                            </button>
                                        )}
                                        <button
                                            type="submit"
                                            className="anystr-done-button cour-btn w-100"
                                        >
                                            <b style={{ color: "white" }}>
                                                Submit
                                            </b>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </BottomSheet>

                        {/* checkout */}
                        <BottomSheet
                            open={this.state.checkout}
                            onDismiss={() => this.checkoutSheet()}
                            snapPoints={({ maxHeight }) => 0.4 * maxHeight}
                        >
                            <div className="p-3" style={{ background: "none" }}>
                                <div className="px-3 pt-3">
                                    <div className="d-flex flex justify-content-between">
                                        <div
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Delivery Charge
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Rs{" "}
                                            {Number(delivery_charge).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="d-flex flex justify-content-between pt-3 pb-2">
                                        <div
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Courier Convenience Fee
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Rs {courier_fee}
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex justify-content-between">
                                        <div
                                            style={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Total
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Rs {total_charge}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="position-fixed px-3 w-100"
                                style={{
                                    backgrounColor: "red",
                                    border: "1px 0px 0px 0px solid #aaa",
                                    bottom: "10px",
                                }}
                            >
                                <button
                                    type="button"
                                    className="pickup-button btn btn py-2 w-100"
                                    onClick={(e) => this.CheckOut(e)}
                                >
                                    <b style={{ color: "white" }}>Checkout</b>
                                </button>
                            </div>
                        </BottomSheet>
                    </React.Fragment>
                )}
                {this.state.instruction ||
                this.state.delivery ||
                this.state.address ||
                this.state.packagemenu ||
                couriercategory.length > 0 ? (
                    ""
                ) : (
                    <Footer />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    category: state.mobileitems.category,
    couriercategory: state.mobileitems.couriercategory,
});

export default connect(mapStateToProps, {
    getAllCategory,
    courierCheckOut,
    checkDeliveryAddress,
    checkPickupAddress,
    getAllAnyStoreCourierCategory,
})(Courier);
