import React, { Component } from "react";
import { BiSearch } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { connect, Provider } from "react-redux";
import VendorFooter from "../VendorFooter";
import Loading from "../../Elements/loading";
import { Link } from "react-router-dom";
import Switch from "react-input-switch";
import Fade from "react-reveal/Flip";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import {
    getVendorOrderDetails,
    toggleSingleStore,
    getUserStore,
    getSearchOrders,
} from "../../../redux/vendor/user/action";
import {
    declineVendorOrder,
    acceptVendorOrder,
    prepareVendorOrder,
    pickupVendorOrder,
} from "../../../redux/vendor/order/action";
import { BottomSheet } from "react-spring-bottom-sheet";
import Ink from "react-ink";
import { set } from "lodash";
import Badge from "react-bootstrap/Badge";
import { FaRegClock } from "react-icons/fa";
import { WEBSITE_URL } from "../../../api";
import axios from "axios";
import moment from "moment-timezone";

export class VendorOrder extends Component {
    state = {
        loading: false,
        value: "Close",
        all_order: true,
        order_id: "",
        order_details: [],
        acceptSheet: false,
        declineSheet: false,
        prepareSheet: false,
        pickupSheet: false,
        order_accepted: false,
        inputSearch: false,
        loadingSearch: false,
        store_id: "",
        tab: "new",
        check: false,
        showScheduleModal: false,
        selectedDateTime: "",
        nextBusinessDay: null,
        nextBusinessSchedule: null,
        scheduleDataExists: false,
        customDateSelected: false, // New state for custom date selection
        userStore: [],
        cancellationReason: "",
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props
            .getUserStore(user && user.data && user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false });
                    if (response.payload.store.is_active == 1) {
                        // if (window.ReactNativeWebView) {
                        //     const response = {
                        //         type: 'show_notification',
                        //         data: true
                        //     };
                        //     window.ReactNativeWebView.postMessage(JSON.stringify(response));
                        // }
                        this.setState({
                            value: "Opened",
                            userStore: response.payload.store,
                        });
                    } else {
                        // if (window.ReactNativeWebView) {
                        //     const response = {
                        //         type: 'show_notification',
                        //         data: false
                        //     };
                        //     window.ReactNativeWebView.postMessage(JSON.stringify(response));
                        // }
                        this.setState({
                            value: "Closed",
                            userStore: response.payload.store,
                        });
                    }
                } else {
                    //// console.log(response, 'API error')
                }
            });
        this.props
            .getVendorOrderDetails(user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders,'orders');
                } else {
                    //// console.log('api error')
                }
            });

        // Set an interval to call the refresh function every 30 seconds
        this.refreshInterval = setInterval(this.refreshNoLoading, 10000);

        // Optionally, call the refresh function immediately
        this.refresh();
    }

    componentWillUnmount() {
        // Clear the interval when the component is unmounted
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }
    refresh = () => {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props
            .getVendorOrderDetails(user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders);
                } else {
                    //// console.log('api error')
                }
            });
    };

    refreshNoLoading = () => {
        const { user } = this.props;
        this.props
            .getVendorOrderDetails(user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders);
                } else {
                    //// console.log('api error')
                }
            });
    };
    _acceptSheetOpen = (order_id) => {
        this.setState({ acceptSheet: true, order_id: order_id, check: false });
    };
    _acceptSheetClose = () => {
        this.setState({ acceptSheet: false, check: false });
    };
    _declineSheetOpen = (order_id) => {
        this.setState({ declineSheet: true, order_id: order_id });
    };
    _declineSheetClose = () => {
        this.setState({ declineSheet: false });
    };
    _prepareSheetOpen = (order_id) => {
        this.setState({ prepareSheet: true, order_id: order_id });
    };
    _prepareSheetClose = () => {
        this.setState({ prepareSheet: false });
    };
    _pickupSheetOpen = (order_id) => {
        this.setState({ pickupSheet: true, order_id: order_id });
    };
    _pickupSheetClose = () => {
        this.setState({ pickupSheet: false });
    };

    openSearch = () => {
        this.setState({ inputSearch: !this.state.inputSearch });
    };

    getSearchOrders = (order_id) => {
        const { user } = this.props;
        this.setState({ loadingSearch: true });
        this.props
            .getSearchOrders(user.data.auth_token, order_id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loadingSearch: false,
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders);
                } else {
                    //// console.log('api error')
                }
            });
    };

    __handleOrder = (Order) => {
        this.setState({ loading: true });
        const { user } = this.props;
        // e.prevent.default();
        if (Order == "Accept") {
            // var check = document.getElementById(this.state.order_id + 'check').checked;
            this.props
                .acceptVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.props
                            .getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (
                                    response &&
                                    response.payload &&
                                    response.payload.success
                                ) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        is_accepted: true,
                                        acceptSheet: false,
                                        order_accepted: true,
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                });
        } else {
            this.props
                .declineVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.props
                            .getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (
                                    response &&
                                    response.payload &&
                                    response.payload.success
                                ) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        is_declined: true,
                                        declineSheet: false,
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                });
        }
    };
    __handlePrepareOrder = (order) => {
        this.setState({ loading: true });
        const { user } = this.props;
        // e.prevent.default();
        if (order == "prepare") {
            this.props
                .prepareVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.props
                            .getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (
                                    response &&
                                    response.payload &&
                                    response.payload.success
                                ) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        prepareSheet: false,
                                        check: false,
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                });
        }
    };
    __handlePickupOrder = (order) => {
        this.setState({ loading: true });
        const { user } = this.props;
        if (order == "pickup") {
            this.props
                .pickupVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.props
                            .getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (
                                    response &&
                                    response.payload &&
                                    response.payload.success
                                ) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        pickupSheet: false,
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                });
        }
    };

    getNextBusinessDay = () => {
        const daysOfWeek = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
        ];
        const today = new Date();
        let nextDayIndex = today.getDay() + 1; // Get the index of the next day

        if (nextDayIndex === daysOfWeek.length) {
            nextDayIndex = 0; // If today is Saturday, the next day is Sunday
        }

        return daysOfWeek[nextDayIndex];
    };

    changeStatus = () => {
        const { user } = this.props;

        if (
            this.state.value == "Closed" ||
            this.state.userStore?.is_schedule == 0
        ) {
            this.setState({ loading: true });

            this.props
                .toggleSingleStore(user && user.data && user.data.auth_token)
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        if (this.state.value == "Closed") {
                            this.setState({ value: "Opened" });
                            setTimeout(() => {
                                this.setState({ loading: false });
                            }, 500);
                        } else {
                            this.setState({ value: "Closed" });
                            setTimeout(() => {
                                this.setState({ loading: false });
                            }, 500);
                        }
                    } else {
                        //// console.log('API error')
                    }
                });

            if (window.ReactNativeWebView) {
                const response = {
                    type: "show_notification",
                    data: true,
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(response));
            }
        } else {
            const scheduleData = this.state.userStore?.schedule_data
                ? JSON.parse(this.state.userStore?.schedule_data)
                : {};
            const nextBusinessDay = this.getNextBusinessDay(); // Calculate next business day dynamically
            const nextBusinessSchedule = scheduleData[nextBusinessDay] || null;

            if (nextBusinessSchedule && nextBusinessSchedule?.length) {
                const openingTime = nextBusinessSchedule[0].open;
                const nextBusinessDate = moment.tz("Asia/Kolkata");
                while (
                    nextBusinessDate.format("dddd").toLowerCase() !==
                    nextBusinessDay
                ) {
                    nextBusinessDate.add(1, "day");
                }
                const [hours, minutes] = openingTime.split(":").map(Number);
                nextBusinessDate.set({
                    hour: hours,
                    minute: minutes,
                    second: 0,
                    millisecond: 0,
                });

                // Format the date to ISO 8601 in the local time zone
                const selectedDateTime = nextBusinessDate.format(); // This keeps it in the local time zone

                this.setState({
                    showScheduleModal: true,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: Boolean(nextBusinessSchedule),
                    selectedDateTime,
                });
            } else {
                this.setState({
                    showScheduleModal: true,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: false,
                });
            }
        }
    };

    showSheduleOrder = (date, time) => {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        let hours = now.getHours();
        let minutes = now.getMinutes();

        let formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
            day
        ).padStart(2, "0")}`;
        let formattedTime = `${String(hours).padStart(2, "0")}:${String(
            minutes
        ).padStart(2, "0")}`;

        let currentDateTime = new Date(formattedDate + "T" + formattedTime);
        let scheduleDateTime = new Date(date + "T" + time);

        // console.log(scheduleDateTime, currentDateTime);

        let diffMs = scheduleDateTime - currentDateTime;
        let diffMins = diffMs / (1000 * 60);
        if (diffMins < 45) {
            return true;
        } else {
            return false;
        }
    };

    handleOptionChange = (event) => {
        const isCustomDate = event.target.value === "custom";
        this.setState({ customDateSelected: isCustomDate });
    };

    handleScheduleSubmit = () => {
        const { selectedItem, selectedDateTime } = this.state;
        const { user } = this.props;

        // alert(selectedDateTime)
        axios
            .post(WEBSITE_URL + "/add-store-reopen-time", {
                date: selectedDateTime,
                id: user.data.store[0]?.id,
            })
            .then((response) => {
                if (this.state.value == "Closed") {
                    this.setState({ value: "Opened" });
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 500);
                } else {
                    this.setState({ value: "Closed" });
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 500);
                }
            });
        if (window.ReactNativeWebView) {
            const response = {
                type: "show_notification",
                data: false,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        this.setState({
            showScheduleModal: false,
            selectedDateTime: "",
            customDateSelected: false,
        });
    };
    render() {
        const {
            order_details,
            showScheduleModal,
            selectedItem,
            selectedDateTime,
            nextBusinessDay,
            nextBusinessSchedule,
            scheduleDataExists,
            customDateSelected,
        } = this.state;
        let showOrders = [];
        let newOrderCount = 0;
        let preparingOrderCount = 0;
        let ongoingOrderCount = 0;

        // Calculate counts
        newOrderCount = order_details.filter((order) => {
            if (order.order_status_id == 1) {
                if (order.is_schedule == 1) {
                    return this.showSheduleOrder(
                        order.schedule_date,
                        order.schedule_time
                    );
                }
                return true;
            }
            return false;
        }).length;

        preparingOrderCount = order_details.filter(
            (order) =>
                order.order_status_id == 15 || order.order_status_id == "2"
        ).length;

        ongoingOrderCount = order_details.filter(
            (order) =>
                order.order_status_id == 3 ||
                order.order_status_id == 4 ||
                order.order_status_id == 7 ||
                order.order_status_id == 12 ||
                order.order_status_id == 13
        ).length;

        if (this.state.tab === "new") {
            showOrders = order_details.filter((order) => {
                if (order.order_status_id == 1) {
                    if (order.is_schedule == 1) {
                        return this.showSheduleOrder(
                            order.schedule_date,
                            order.schedule_time
                        );
                        //return this.showSheduleOrder("2024-05-18", "09:40");
                    }
                    return true;
                }
            });
        } else if (this.state.tab === "preparing") {
            showOrders = order_details.filter(
                (order) =>
                    order.order_status_id == 15 || order.order_status_id == "2"
            );
        } else if (this.state.tab === "ongoing") {
            showOrders = order_details.filter(
                (order) =>
                    order.order_status_id == 3 ||
                    order.order_status_id == 4 ||
                    order.order_status_id == 7 ||
                    order.order_status_id == 12 ||
                    order.order_status_id == 13
            );
        }
        const { order, user } = this.props;
        return (
            <React.Fragment>
                {/* Schedule Modal */}
                <Modal
                    show={showScheduleModal}
                    style={{ marginTop: 200 }}
                    onHide={() => this.setState({ showScheduleModal: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Store Reopening</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {scheduleDataExists ? (
                            <div>
                                <form>
                                    <div className="form-group">
                                        <label>Reopen Time</label>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`next-business-day-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="next-business-day"
                                                checked={!customDateSelected}
                                                onChange={
                                                    this.handleOptionChange
                                                }
                                            />
                                            <label
                                                className="ms-2"
                                                htmlFor={`next-business-day-radio${selectedItem?.id}`}
                                            >
                                                Next business day at{" "}
                                                {nextBusinessSchedule &&
                                                    nextBusinessSchedule[0]
                                                        .open}
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`choose-time-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="custom"
                                                checked={customDateSelected}
                                                onChange={
                                                    this.handleOptionChange
                                                }
                                            />
                                            <label
                                                className="ms-2"
                                                htmlFor={`choose-time-radio${selectedItem?.id}`}
                                            >
                                                I will choose the time
                                            </label>
                                        </div>
                                        {customDateSelected && (
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={selectedDateTime}
                                                onChange={(e) =>
                                                    this.setState({
                                                        selectedDateTime:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className="text-danger fw-bold fs-7">
                                Oops... Please turn on auto schedule and add
                                scheduled opening and closing time for store.
                                Then only store will be automatically turned on
                                for the selected date and time.
                                <br />
                                <Link to={"/vendor-timing"}>
                                    <Button
                                        variant="primary"
                                        className="btn text-white inventory-browse-btn fs-6 mt-2"
                                        onClick={this.handleScheduleSubmit}
                                    >
                                        Click to add scheduled opening and
                                        closing time for your store
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() =>
                                this.setState({ showScheduleModal: false })
                            }
                        >
                            Close
                        </Button>
                        {scheduleDataExists && (
                            <Button
                                variant="primary"
                                className="btn text-white inventory-browse-btn"
                                onClick={this.handleScheduleSubmit}
                            >
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    order &&
                    order.orders && (
                        <React.Fragment>
                            {order_details && (
                                <React.Fragment>
                                    {/* {//// console.log(order)} */}
                                    <div
                                        className="position-sticky"
                                        style={{
                                            top: "0px",
                                            zIndex: "9999999",
                                            backgroundColor: "#fff",
                                        }}
                                    >
                                        <div className="d-flex flex-row justify-content-between align-items-center pt-4 px-3">
                                            <div className=" d-flex flex-column">
                                                <span className="nameRestaurant">
                                                    {user.data &&
                                                        user.data.store[0] &&
                                                        user.data.store[0].name}
                                                </span>
                                                <span className="address text-muted">
                                                    {user.data &&
                                                        user.data.store[0] &&
                                                        user.data.store[0]
                                                            .address}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                {!this.state.inputSearch && (
                                                    <span
                                                        onClick={
                                                            this.openSearch
                                                        }
                                                    >
                                                        <BiSearch
                                                            set="curved"
                                                            style={{
                                                                fontSize:
                                                                    "1.7em",
                                                                color: "#FEA24B",
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                                <div className="store-toggle-btn py-2 px-2 d-flex justify-content-center align-items-center ms-2">
                                                    <div className="ps-1 pe-3">
                                                        {this.state.value}
                                                    </div>
                                                    <Switch
                                                        on="Opened"
                                                        off="Closed"
                                                        value={this.state.value}
                                                        onChange={
                                                            this.changeStatus
                                                        }
                                                        styles={{
                                                            track: {
                                                                backgroundColor:
                                                                    "#fff",
                                                            },
                                                            trackChecked: {
                                                                backgroundColor:
                                                                    "#fff",
                                                            },
                                                            button: {
                                                                backgroundColor:
                                                                    "#FF9012",
                                                            },
                                                            buttonChecked: {
                                                                backgroundColor:
                                                                    "#FF9012",
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-row justify-content-between align-items-center status px-3 py-2 mt-2 ">
                                            {this.state.value == "Opened" ? (
                                                <span className="status-text">
                                                    You are Online
                                                </span>
                                            ) : (
                                                <span className="status-offline">
                                                    You are Offline
                                                </span>
                                            )}
                                            {this.state.value == "Opened" && (
                                                <div
                                                    className="btn"
                                                    style={{
                                                        borderRadius: "10px",
                                                        color: "#fff",
                                                        backgroundColor:
                                                            "#ff9012",
                                                    }}
                                                    onClick={this.refresh}
                                                >
                                                    <BiRefresh size="1.5em" />{" "}
                                                    Refresh
                                                </div>
                                            )}
                                        </div>
                                        <Fade
                                            collapse
                                            top
                                            when={this.state.inputSearch}
                                        >
                                            <div className="d-flex flex-column ">
                                                <div className="status">
                                                    <div className="d-flex flex-row justify-content-between align-items-center px-3 py-2">
                                                        <InputGroup
                                                            hasValidation
                                                            className="b-r-10 phone-input py-2 w-100"
                                                            style={{
                                                                boxSizing:
                                                                    "border-box",
                                                            }}
                                                        >
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Search Order Id"
                                                                required
                                                                autoComplete={
                                                                    false
                                                                }
                                                                name="text"
                                                                className="input-login-phone w-100"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    let value =
                                                                        e.target
                                                                            .value;
                                                                    this.getSearchOrders(
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        {/* <div className="btn px-2 py-2" style={{ borderRadius: '10px', color: '#fff', backgroundColor: '#ff9012' }}>
                                                                <BiSearch set="curved" style={{ fontSize: "1.7em", color: "#fff" }} />
                                                            </div> */}
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <span
                                                        onClick={
                                                            this.openSearch
                                                        }
                                                    >
                                                        <AiFillCloseCircle
                                                            size={30}
                                                            color="#e55252"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>
                                    {this.state.loadingSearch ? (
                                        <Loading />
                                    ) : (
                                        this.state.all_order && (
                                            <div
                                                style={{ paddingBottom: "30%" }}
                                            >
                                                <div className="px-3 mt-3">
                                                    <div className="d-flex justify-content-between gap-2">
                                                        <button
                                                            className={
                                                                this.state
                                                                    .tab ==
                                                                "new"
                                                                    ? "order-status-btn-active"
                                                                    : "order-status-btn"
                                                            }
                                                            onClick={() =>
                                                                this.setState({
                                                                    tab: "new",
                                                                })
                                                            }
                                                        >
                                                            New ({newOrderCount}
                                                            )
                                                        </button>
                                                        <button
                                                            className={
                                                                this.state
                                                                    .tab ==
                                                                "preparing"
                                                                    ? "order-status-btn-active"
                                                                    : "order-status-btn "
                                                            }
                                                            onClick={() =>
                                                                this.setState({
                                                                    tab: "preparing",
                                                                })
                                                            }
                                                        >
                                                            Preparing (
                                                            {
                                                                preparingOrderCount
                                                            }
                                                            )
                                                        </button>
                                                        <button
                                                            className={
                                                                this.state
                                                                    .tab ==
                                                                "ongoing"
                                                                    ? "order-status-btn-active"
                                                                    : "order-status-btn"
                                                            }
                                                            onClick={() =>
                                                                this.setState({
                                                                    tab: "ongoing",
                                                                })
                                                            }
                                                        >
                                                            Ongoing (
                                                            {ongoingOrderCount})
                                                        </button>
                                                    </div>
                                                </div>
                                                {showOrders &&
                                                showOrders.length > 0 ? (
                                                    <div>
                                                        {showOrders &&
                                                            showOrders.map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <div className="card-restaurant p-3 mt-3">
                                                                        <Link
                                                                            to={
                                                                                "/vendor-single-order/" +
                                                                                value.id +
                                                                                "/order"
                                                                            }
                                                                        >
                                                                            {value.is_prescription ? (
                                                                                <div className="text-end">
                                                                                    <Badge bg="danger">
                                                                                        With
                                                                                        prescription
                                                                                    </Badge>
                                                                                </div>
                                                                            ) : null}
                                                                            <div className="d-flex flex-row justify-content-between">
                                                                                <div>
                                                                                    <TbTruckDelivery
                                                                                        style={{
                                                                                            color: "#888",
                                                                                            fontSize:
                                                                                                "1.5em",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="text-end"
                                                                                    style={{
                                                                                        color: "#000",
                                                                                        fontWeight:
                                                                                            "800",
                                                                                    }}
                                                                                >
                                                                                    {value &&
                                                                                        value.orderstatus &&
                                                                                        value.orderstatus &&
                                                                                        value
                                                                                            .orderstatus
                                                                                            .name}
                                                                                </div>
                                                                                {/* completed order style */}
                                                                                {/* {value.order_status_id != 5 ?
                                                                        <div>
                                                                            <TbTruckDelivery style={{ color: '#888', fontSize: '1.5em' }} />
                                                                        </div>
                                                                        :
                                                                        value.order_status_id == 5 ?
                                                                            <div>
                                                                                <IoCheckmarkCircleSharp style={{ color: '#32ba7c', fontSize: '1.5em' }} />
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {value.order_status_id != 5 ?
                                                                        <div className="text-end" style={{ color: '#000', fontWeight: '800' }}>
                                                                            {value && value.orderstatus && value.orderstatus &&
                                                                                value.orderstatus.name}
                                                                        </div>
                                                                        :
                                                                        value.order_status_id == 5 ?
                                                                            <div className="text-end" style={{ color: '#32ba7c', fontWeight: '800' }}>
                                                                                Order Completed
                                                                            </div>
                                                                            :
                                                                            null
                                                                    } */}
                                                                            </div>
                                                                            <div className="d-flex justify-content-between mb-1 mt-1">
                                                                                <div className="explore-general-one">
                                                                                    <span className="text-muted">
                                                                                        Order
                                                                                        ID
                                                                                    </span>
                                                                                </div>
                                                                                <div className="explore-general-two">
                                                                                    <span className="text-muted">
                                                                                        {value.unique_order_id ? (
                                                                                            <>
                                                                                                {value
                                                                                                    .unique_order_id
                                                                                                    .length >
                                                                                                5
                                                                                                    ? value.unique_order_id.slice(
                                                                                                          0,
                                                                                                          -5
                                                                                                      ) // Display all characters except the last 5
                                                                                                    : null}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        fontWeight:
                                                                                                            "bold",
                                                                                                    }}
                                                                                                >
                                                                                                    {value.unique_order_id.slice(
                                                                                                        -5
                                                                                                    )}{" "}
                                                                                                    {/* Last 5 characters in red */}
                                                                                                </span>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                {value.orderitems.map(
                                                                                    (
                                                                                        data,
                                                                                        index
                                                                                    ) => (
                                                                                        <div className="d-flex justify-content-between mb-1">
                                                                                            <div className="explore-general-one">
                                                                                                <span className="text-muted">
                                                                                                    {data.name
                                                                                                        ? data.name
                                                                                                        : null}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="explore-general-two">
                                                                                                <span className="text-muted">
                                                                                                    Qty:{" "}
                                                                                                    {data.quantity
                                                                                                        ? data.quantity
                                                                                                        : null}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                            {value.total ? (
                                                                                <div className="d-flex justify-content-between mb-1">
                                                                                    <div className="explore-general-one">
                                                                                        <span className="text-muted">
                                                                                            Total
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="explore-general-two">
                                                                                        <span className="text-muted">
                                                                                            Rs.{" "}
                                                                                            {value.total
                                                                                                ? value.total
                                                                                                : null}{" "}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}

                                                                            {value.is_schedule ===
                                                                                1 && (
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center"
                                                                                    style={{
                                                                                        color: "#fd8814",
                                                                                        border: "1px solid",
                                                                                        borderColor:
                                                                                            "#fd8814",
                                                                                        padding:
                                                                                            "7px 10px",
                                                                                        borderRadius:
                                                                                            "10px",
                                                                                        marginTop:
                                                                                            "15px",
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <FaRegClock
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "19px",
                                                                                                marginRight:
                                                                                                    "3px",
                                                                                            }}
                                                                                        />{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "15px",
                                                                                            }}
                                                                                        >
                                                                                            Scheduled
                                                                                        </span>
                                                                                    </div>

                                                                                    <div>
                                                                                        <span>
                                                                                            {
                                                                                                value.schedule_date
                                                                                            }{" "}
                                                                                            {
                                                                                                value.schedule_time
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {/* <div className="d-flex justify-content-between mb-1">
                                                                    <div className="explore-general-one">
                                                                        <span className="text-muted">
                                                                            Total
                                                                        </span>
                                                                    </div>
                                                                    <div className="explore-general-two">
                                                                        <span className="text-muted">
                                                                            Rs.{" "}
                                                                            {value.total ? value.total : null}{" "}
                                                                        </span>
                                                                    </div>
                                                                </div> */}
                                                                        </Link>
                                                                        {/* {value.order_status_id == 2 ?
                                                                <div className="d-flex justify-content-end vendor-store-btn mt-2 px-1">
                                                                    <button className="prepare-btn text-success py-2 px-2"
                                                                        onClick={() => this._prepareSheetOpen(value.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="currentColor" className="bi bi-check-all" viewBox="0 0 16 16">
                                                                            <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                                                        </svg>
                                                                        <span className='ps-2'>Prepare</span>
                                                                    </button>
                                                                </div>
                                                                : null} */}
                                                                        {value.order_status_id ==
                                                                        15 ? (
                                                                            <div className="d-flex justify-content-end vendor-store-btn mt-2 px-1">
                                                                                <button
                                                                                    className="accept-button-ready-pickup py-2 px-3"
                                                                                    onClick={() =>
                                                                                        this._pickupSheetOpen(
                                                                                            value.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16"
                                                                                        height="16"
                                                                                        fill="currentColor"
                                                                                        className="bi bi-cart-plus"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                                                                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                                    </svg>
                                                                                    <span className="ps-2">
                                                                                        Ready
                                                                                        To
                                                                                        Pick
                                                                                        Up
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        ) : null}

                                                                        {value.is_medicine ==
                                                                        1 ? (
                                                                            <Link
                                                                                to={
                                                                                    "/vendor-prescription/" +
                                                                                    value.id
                                                                                }
                                                                            >
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <button className="medicine-button py-1 px-2">
                                                                                        View
                                                                                        Prescription
                                                                                    </button>
                                                                                </div>
                                                                            </Link>
                                                                        ) : value.order_status_id ==
                                                                          1 ? (
                                                                            <div className="d-flex justify-content-between  vendor-store-btnpx-1">
                                                                                {/* <button className="decline-button py-2 px-4"
                                                                            onClick={() => this._declineSheetOpen(value.id)}>
                                                                            Cancel
                                                                        </button>
                                                                        <button className="accept-button py-2 px-4"
                                                                            onClick={() => this._acceptSheetOpen(value.id)}>
                                                                            Accept
                                                                        </button> */}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                ) : (
                                                    <div className="no-order-text text-muted d-flex align-items-center justify-content-center">
                                                        No Orders Yet ....!
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                    {/* accept  */}
                                    <BottomSheet
                                        open={this.state.acceptSheet}
                                        onDismiss={() =>
                                            this._acceptSheetClose()
                                        }
                                    >
                                        <div
                                            className="d-flex flex-column align-items-center"
                                            style={{
                                                backgroundColor: "#fff",
                                                padding: "13px",
                                            }}
                                        >
                                            <div className="text-center">
                                                <h3>Accept Order</h3>
                                            </div>
                                            <div className="text-muted ms-4 mt-3">
                                                <span>
                                                    Are you sure do you wanna
                                                    Accept order?
                                                </span>
                                            </div>
                                            <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                                <div
                                                    className="text-center position-relative py-3 decline-button"
                                                    onClick={(e) =>
                                                        this._acceptSheetClose(
                                                            e
                                                        )
                                                    }
                                                >
                                                    Close
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="text-center accept-button ms-2 py-3 position-relative"
                                                    onClick={() =>
                                                        this.__handleOrder(
                                                            "Accept"
                                                        )
                                                    }
                                                >
                                                    Accept Order
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    {/* decline */}
                                    <BottomSheet
                                        open={this.state.declineSheet}
                                        onDismiss={() =>
                                            this._declineSheetClose()
                                        }
                                    >
                                        <div
                                            className="d-flex flex-column align-items-center"
                                            style={{
                                                backgroundColor: "#fff",
                                                padding: "13px",
                                            }}
                                        >
                                            <div className="text-center">
                                                <h3>Cancel Order</h3>
                                            </div>
                                            <div className="text-muted ms-4 mt-3">
                                                <span>
                                                    Are you sure you want to
                                                    cancel the order?
                                                </span>
                                            </div>

                                            {/* Cancellation reason input field */}
                                            <div className="mt-3 w-100">
                                                <label
                                                    htmlFor="cancellationReason"
                                                    className="form-label"
                                                >
                                                    Reason for Cancellation
                                                </label>
                                                <input
                                                    type="text"
                                                    id="cancellationReason"
                                                    className="form-control"
                                                    placeholder="Enter your reason here"
                                                    value={
                                                        this.state
                                                            .cancellationReason
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            cancellationReason:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>

                                            <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                                <div
                                                    className="text-center py-3 position-relative decline-button"
                                                    onClick={
                                                        this._declineSheetClose
                                                    }
                                                >
                                                    Close
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="text-center accept-button ms-2 py-3 position-relative"
                                                    onClick={() =>
                                                        this.__handleOrder(
                                                            "Decline",
                                                            this.state
                                                                .cancellationReason
                                                        )
                                                    }
                                                >
                                                    Cancel Order
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>

                                    {/* prepare */}
                                    <BottomSheet
                                        open={this.state.prepareSheet}
                                        onDismiss={() =>
                                            this._prepareSheetClose()
                                        }
                                    >
                                        <div
                                            className="d-flex flex-column align-items-center"
                                            style={{
                                                backgroundColor: "#fff",
                                                padding: "13px",
                                            }}
                                        >
                                            <div className="text-center">
                                                <h3>Prepare Order</h3>
                                            </div>
                                            <div className="text-muted ms-4 mt-3">
                                                <span>
                                                    Are you sure to Prepare this
                                                    order?
                                                </span>
                                            </div>
                                            <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                                <div
                                                    className="text-center position-relative py-3 decline-button"
                                                    onClick={(e) =>
                                                        this._prepareSheetClose(
                                                            e
                                                        )
                                                    }
                                                >
                                                    Close
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="text-center accept-button ms-2 py-3 position-relative"
                                                    onClick={() =>
                                                        this.__handlePrepareOrder(
                                                            "prepare"
                                                        )
                                                    }
                                                >
                                                    Prepare Order
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    {/* ready to pick up */}
                                    <BottomSheet
                                        open={this.state.pickupSheet}
                                        onDismiss={() =>
                                            this._pickupSheetClose()
                                        }
                                    >
                                        <div
                                            className="d-flex flex-column align-items-center"
                                            style={{
                                                backgroundColor: "#fff",
                                                padding: "13px",
                                            }}
                                        >
                                            <div className="text-center">
                                                <h3>Pickup Order</h3>
                                            </div>
                                            <div className="text-muted ms-4 mt-3">
                                                <span>
                                                    Are you sure the order is
                                                    ready to be picked up?
                                                </span>
                                            </div>
                                            <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                                <div
                                                    className="text-center position-relative py-3 decline-button"
                                                    onClick={(e) =>
                                                        this._pickupSheetClose(
                                                            e
                                                        )
                                                    }
                                                >
                                                    Close
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="text-center accept-button ms-2 py-3 position-relative"
                                                    onClick={() =>
                                                        this.__handlePickupOrder(
                                                            "pickup"
                                                        )
                                                    }
                                                >
                                                    Pickup Order
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#AAAAAA",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                </React.Fragment>
                            )}
                            <VendorFooter active_orders={true} />
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    order: state.vendor.order,
    user: state.vendor.store_user,
});

export default connect(mapStateToProps, {
    getVendorOrderDetails,
    toggleSingleStore,
    declineVendorOrder,
    acceptVendorOrder,
    getUserStore,
    getSearchOrders,
    prepareVendorOrder,
    pickupVendorOrder,
})(VendorOrder);
