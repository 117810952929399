import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButton from "../../../Elements/MobileBackButtonWithCenterTitle";

import { Rating } from "react-simple-star-rating";
import {
    AiOutlineHeart,
    AiFillHeart,
    AiOutlinePlus,
    AiOutlineMinus,
    AiOutlineStop,
    AiFillStar,
} from "react-icons/ai";
import { BottomSheet } from "react-spring-bottom-sheet";
import image from "../../../../assets/images/noitems.png";
import { Link } from "react-router-dom";
// import { RiShareForwardLine } from "react-icons/ri";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import ProgressiveImage from "react-progressive-image";
import ContentLoader from "react-content-loader";
import { getSingleItemGroup } from "../../../../redux/mobile/item/action";
import { toggleFavItem } from "../../../../redux/mobile/item/action";
import {
    addProduct,
    removeProduct,
} from "../../../../redux/mobile/cart/action";
// import PopularItems from "./PopularItems";
import FloatCart from "../../Cart/FloatCart";
import { GiScooter } from "react-icons/gi";
import Truncate from "react-truncate";
// import Items from "./Items";

export class ListStoreGroup extends Component {
    state = {
        cart: false,
        isveg: true,
        isnonveg: true,
        couponsheet: false,
        coupon: {},
        loading: false,
        id: this.props.match.params.id,
        rating: [],
        items: {},
        category_id: "",
        copied: false,
        copied_code: null,
    };
    componentDidMount() {
        //// console.log('====================================');
        //// console.log(window.innerWidth);
        //// console.log('====================================');
        this.setState({ loading: true });
        this.props
            .getSingleItemGroup(
                this.state.id,
                JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
                JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude
            )
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        itemGroup: response.payload.item,
                    });
                } else {
                    ////// console.log("API error");
                }
            });
    }
    // toggleFavItem = (item) => {
    //     const { categoryitems } = this.state;
    //     const { user } = this.props;
    //     const singleItem = categoryitems.find((data) => data.id === item.id);
    //     const favUser = singleItem.favorites.find(
    //         (fav) => fav.user_id === user.data.id
    //     );
    //     if (favUser) {
    //         const favIndex = singleItem.favorites.indexOf(favUser);
    //         singleItem.favorites.splice(favIndex, 1);
    //         this.setState({ categoryitems: categoryitems });
    //     } else {
    //         singleItem.favorites.push({ user_id: user.data.id });
    //         this.setState({ categoryitems: categoryitems });
    //     }
    //     this.props.toggleFavItem(this.props.user.data.auth_token, item.id);
    // };
    // filterCategory = (category) => {
    //     const { items } = this.state;
    //     if (category === "ALL") {
    //         const newitems = items;
    //         this.setState(
    //             { categoryitems: newitems, category_id: category },
    //             this.closeMenu()
    //         );
    //     } else {
    //         let data = parseInt(category);
    //         const newitems = items.filter(
    //             (item) => item.item_category_id === data
    //         );
    //         this.setState(
    //             { categoryitems: newitems, category_id: category },
    //             this.closeMenu()
    //         );
    //     }
    // };
    // openCoupon = (data) => {
    //     this.setState({ couponsheet: true, coupon: data, floatCart: true });
    // };
    // closeCoupon = () => {
    //     this.setState({ couponsheet: false, floatCart: false });
    // };
    // openMenu = () => {
    //     this.setState({ cart: true, floatCart: true });
    // };
    // closeMenu = () => {
    //     this.setState({ cart: false, floatCart: false });
    // };
    // toggleItemVeg = (type) => {
    //     if (type === "VEG") {
    //         if (this.state.isveg === false) {
    //             this.setState({ isveg: true });
    //         } else {
    //             if (this.state.isnonveg === true) {
    //                 this.setState({ isveg: false });
    //             } else {
    //                 this.setState({ isveg: true, isnonveg: true });
    //             }
    //         }
    //     } else {
    //         if (this.state.isnonveg === false) {
    //             this.setState({ isnonveg: true });
    //         } else {
    //             if (this.state.isveg === true) {
    //                 this.setState({ isnonveg: false });
    //             } else {
    //                 this.setState({ isnonveg: true, isveg: true });
    //             }
    //         }
    //     }
    // };
    // forceStateUpdate = () => {
    //     setTimeout(() => {
    //         this.forceUpdate();
    //         if (this.state.update) {
    //             this.setState({ update: false });
    //         } else {
    //             this.setState({ update: true });
    //         }
    //     }, 100);
    // };

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        });
        total = Math.round((total / count) * 100) / 100;
        return total;
    };
    render() {
        const { user, store, addProduct, cartProducts, removeProduct } =
            this.props;
        const { categoryitems, itemGroup } = this.state;
        let showItems = [];
        if (this.state.isveg === true && this.state.isnonveg === false) {
            showItems = categoryitems.filter((item) => item.is_veg === 1);
        } else if (this.state.isveg === false && this.state.isnonveg === true) {
            showItems = categoryitems.filter((item) => item.is_veg === 0);
        } else {
            showItems = categoryitems;
        }
        return (
            <React.Fragment>
                {this.state?.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="10"
                                y="20"
                                rx="5"
                                ry="5"
                                width="22"
                                height="22"
                            />
                            <rect
                                x="13"
                                y="55"
                                rx="0"
                                ry="0"
                                width="140"
                                height="30"
                            />
                            <rect
                                x="13"
                                y="100"
                                rx="0"
                                ry="0"
                                width="120"
                                height="15"
                            />
                            <rect
                                x="200"
                                y="61"
                                rx="10"
                                ry="10"
                                width="30"
                                height="30"
                            />
                            <rect
                                x="13"
                                y="120"
                                rx="5"
                                ry="5"
                                width="240"
                                height="100"
                            />
                            <rect
                                x="13"
                                y="230"
                                rx="10"
                                ry="10"
                                width="120"
                                height="40"
                            />
                            <rect
                                x="140"
                                y="230"
                                rx="10"
                                ry="10"
                                width="120"
                                height="40"
                            />
                            <rect
                                x="130"
                                y="290"
                                rx="10"
                                ry="10"
                                width="30"
                                height="20"
                            />
                            <rect
                                x="164"
                                y="295"
                                rx="0"
                                ry="0"
                                width="20"
                                height="10"
                            />
                            <rect
                                x="200"
                                y="290"
                                rx="10"
                                ry="10"
                                width="30"
                                height="20"
                            />
                            <rect
                                x="235"
                                y="295"
                                rx="0"
                                ry="0"
                                width="30"
                                height="10"
                            />
                            <rect
                                x="13"
                                y="320"
                                rx=""
                                ry=""
                                width="87"
                                height="15"
                            />
                            <rect
                                x="15"
                                y="350"
                                rx="10"
                                ry="10"
                                width="250"
                                height="100"
                            />
                            <rect
                                x="15"
                                y="460"
                                rx="10"
                                ry="10"
                                width="250"
                                height="100"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {itemGroup && (
                            <div
                                className="store-bg-image pt-4 px-3"
                                style={{ paddingBottom: "5vh" }}
                            >
                                <div
                                    className=""
                                    style={{ marginLeft: "-2vh" }}
                                >
                                    <MobileBackButton
                                        history={this.props.history}
                                        title={itemGroup?.name}
                                    />
                                </div>
                                {/* <div className="mt-5 d-flex justify-content-between">
                                    <h2 className="" style={{ fontWeight: "700", fontSize: "31px", }}   >
                                        {itemGroup.name}
                                    </h2>

                                </div> */}

                                <div className="frame-img py-2 mt-2">
                                    <div className="d-flex position-relative">
                                        <ProgressiveImage
                                            delay={20}
                                            src={
                                                IMAGE_BASE_URL +
                                                itemGroup?.background_image
                                            }
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        width: "90vw",
                                                        height: "40vw",
                                                        borderRadius: "20px",
                                                        objectFit: "cover",
                                                        backgroundRepeat:
                                                            "round",
                                                    }}
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                        <div
                                            className="p-2 d-flex justify-content-between position-absolute w-100"
                                            style={{
                                                top: "15px",
                                                right: "7px",
                                            }}
                                        ></div>
                                        <div
                                            className="d-flex position-absolute"
                                            style={{
                                                top: "58px",
                                                left: "-3px",
                                            }}
                                        ></div>
                                    </div>
                                </div>

                                {itemGroup && itemGroup.stores && itemGroup.stores.length > 0 ? (
    <div>
        <div className="description mt-2">
            Stores
        </div>
        {itemGroup.stores
            .sort((a, b) => b.is_active - a.is_active) // Sort active stores first
            .map((store) => (
                <div key={store.id}>
                    <Link to={`/single-store/${store.id}`}>
                        <div
                            className={`px-3 mt-3 d-flex align-items-center position-relative ${
                                store.is_active === 0 ? "store-not-active" : ""
                            }`}
                        >
                            <div>
                                <ProgressiveImage
                                    delay={20}
                                    src={IMAGE_BASE_URL + store?.image}
                                    placeholder={PLACE_HOLDER_IMAGE}
                                >
                                    {(src, loading) => (
                                        <img
                                            src={src}
                                            style={{
                                                width: "30vw",
                                                height: "30vw",
                                                borderRadius: "20px",
                                                objectFit: "cover",
                                                backgroundRepeat: "round",
                                            }}
                                            alt="store"
                                        />
                                    )}
                                </ProgressiveImage>
                            </div>
                            <div className="ms-3 pe-4 mt-1 w-100">
                                <h5
                                    className="fs-6 m-0"
                                    style={{
                                        color: "#09051C",
                                        fontWeight: "600",
                                    }}
                                >
                                    {store.name}
                                </h5>
                                <div className="d-flex align-items-center">
                                    {store?.ratings?.length > 0 ? (
                                        <>
                                            <span className="m all-resturant-rating">
                                                {this.ratingCount(
                                                    store?.ratings,
                                                    store?.ratings?.length
                                                )}
                                            </span>{" "}
                                            <AiFillStar
                                                className="me-2"
                                                style={{
                                                    color: "FF9A62",
                                                }}
                                                size={11}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <span className="m all-resturant-rating">
                                                {store?.rating}
                                            </span>{" "}
                                            <AiFillStar
                                                className="me-2"
                                                style={{
                                                    color: "FF9A62",
                                                }}
                                                size={11}
                                            />
                                        </>
                                    )}
                                    <span
                                        className="me-2"
                                        style={{
                                            fontSize: "12px",
                                            color: "#3B3B3B",
                                        }}
                                    >
                                        {store?.preparation_time
                                            ? store.preparation_time + " Min - "
                                            : ""}
                                        {store?.delivery_radius
                                            ? store.delivery_radius + " Km "
                                            : ""}
                                    </span>
                                </div>
                                <div
                                    className="resturant-description"
                                    style={{
                                        color: "rgba(9, 5, 28, 0.25)",
                                        fontSize: "12px",
                                    }}
                                >
                                    <Truncate lines="3">
                                        {store?.description}
                                    </Truncate>
                                </div>
                                {store?.is_freedelivery === 1 && (
                                    <div className="d-flex align-items-center">
                                        <GiScooter
                                            style={{ color: "FF9A62" }}
                                            size={20}
                                        />
                                        <span className="all-resturant-delivery ms-2 mt-1">
                                            Free Delivery Upto...
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
    </div>
) : (
    <div
        className="pt-3 d-flex flex-column align-items-center"
        style={{ paddingBottom: "20vw" }}
    >
        <div className="mt-3 d-flex flex-column align-items-center">
            <img
                src={image}
                alt="no items"
                style={{
                    objectFit: "cover",
                    width: "70%",
                }}
            />
        </div>
        <span className="mt-4" style={{ fontSize: "1em" }}>
            Sorry No Store Found !!!
        </span>
    </div>
)}

                            </div>
                        )}

                        <FloatCart
                            float={this.state.floatCart}
                            noFooter={true}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    store: state.singleStore.store,
    user: state.user.user,
    cartProducts: state.cart.products,
});

export default connect(mapStateToProps, {
    getSingleItemGroup,
    toggleFavItem,
    addProduct,
    removeProduct,
})(ListStoreGroup);
