import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../api/index';
import {
    getSingleCoupon,
} from '../../../redux/mobile/home/action'
import { AiFillStar, AiFillTags } from "react-icons/ai"
import { MdNotes, MdOutlineContentCopy,MdDone } from "react-icons/md"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Truncate from 'react-truncate';
import { GiScooter } from 'react-icons/gi';
import { Link } from "react-router-dom";
import { HiTemplate } from 'react-icons/hi';






export class SingleCoupon extends Component {
    state = {
        id: this.props.match.params.id,
        coupon: [],
        copied: false,
    };
    componentDidMount() {
        const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
        const latitude = userSetAddress?.latitude;
        const longitude = userSetAddress?.longitude;
    
        // Assuming you have the coupon ID stored in state
        this.props.getSingleCoupon(this.state.id, latitude, longitude)
            .then((response) => {
                if (response.payload.success) {
                    // Handle successful response here
                    this.setState({ coupon: response.payload.data });
                } else {
                    // Handle error scenario
                    console.error("Failed to retrieve coupon:", response.payload.message);
                }
            })
            .catch((error) => {
                // Handle API call errors
                console.error("API call error:", error);
            });
    }
    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const {coupon} = this.props;
        console.log( coupon?.data?.filteredStores?.length," coupon?.data?.filteredStores")
        return (
            <React.Fragment>
                <div className="mobile-top-offer-bg pt-4 px-4">
                    <div className="">
                        <MobileBackButtonWithCenterTitle title="Coupons"  history={this.props.history}/>
                    </div>
                    <div className="px-1 mt-3">
                        {coupon && coupon.success ? (
                            <>
                                <div className='mobile-single-coupon-img'>
                                    {/* {////// console.log(this.state.coupon?.data.stores.length)} */}
                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + "/" + coupon?.data?.image}
                                        placeholder={PLACE_HOLDER_IMAGE}
                                    >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                className="mobile-single-coupon-img" style={{
                                                    objectFit: 'cover'
                                                }}
                                                alt='main-item'
                                            />
                                        )}

                                    </ProgressiveImage>
                                </div>
                                <div className='mt-3'>
                                    <div className="mt-5 mobile-single-coupon-item pt-1" >
                                        <div className="text-muted ps-3 pt-2" style={{ fontWeight: '600' }}>
                                            <AiFillTags size={"1em"} style={{ color: '#FF9012' }} />
                                            <span className='ps-1'>Coupon </span>
                                        </div>
                                        <div className="ps-4 pt-1 pb-2" style={{ fontWeight: '650' }}>
                                            <span>{coupon?.data?.name}</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 mobile-single-coupon-item pt-1" >
                                        <div className="text-muted ps-3 pt-2" style={{ fontWeight: '600' }}>
                                            <MdNotes size={"1.2em"} style={{ color: '#FF9012' }} />
                                            <span className='ps-1'>Description </span>
                                        </div>
                                        <div className="ps-4 pt-1" style={{ fontWeight: '650' }}>
                                            <span>{coupon?.data?.description}</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 mobile-single-coupon-item pt-1 d-flex justify-content-between" >
                                        <CopyToClipboard text={coupon?.data?.code}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <div className="ps-3 pt-2" style={{ fontWeight: '680' }}>
                                                <MdOutlineContentCopy size={"1.2em"} style={{ color: '#FF9012' }}/>
                                                <span className='text-success ps-3'>{coupon?.data?.code} </span>
                                            </div>
                                        </CopyToClipboard>
                                        {this.state.copied ?
                                        <div className='me-3 py-1 pe-3'>
                                            <MdDone size={'1.2em'} style={{color:'grey'}}/>
                                        <span style={{color: 'grey'}}>Copied</span>
                                        </div>
                                         : null}
                                    </div>

                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>


                    <div className='p-2 mobile-top-offer-item mt-3 mt-2' style={{ fontSize: '17px', fontWeight: '690' }}>
                                            <HiTemplate size={"1.3em"} style={{ color: '#FF9012' }} />
                                            <span className="ps-1"> Coupon Applicable Stores</span>
                                        </div>



{   coupon?.data?.filteredStores?.length> 0 ? (
  <div className='pb-2 pt-1'>
    {/* Loop through all coupons */}
    {
      // Only display stores if they exist and their length is greater than 0
      coupon?.data?.filteredStores?.length > 0 ? (
        coupon?.data?.filteredStores?.map((store) => (
          <div className='mobile-top-offer-item-div py-2 px-2 mt-2' key={store.id}>
            <Link to={`/single-store/${store.id}`}>
              <div className="d-flex flex-row">
                <div className="">
                  <ProgressiveImage
                    delay={20}
                    src={IMAGE_BASE_URL + store?.image}
                    placeholder={PLACE_HOLDER_IMAGE}
                  >
                    {(src, loading) => (
                      <img
                        src={src}
                        style={{
                          width: "30vw",
                          height: "30vw",
                          borderRadius: "20px",
                          objectFit: "cover",
                          backgroundRepeat: "round",
                        }}
                        className=""
                        alt={store.name}
                      />
                    )}
                  </ProgressiveImage>
                </div>
                <div className="flex-grow-1 d-flex flex-column justify-content-center ms-3 mt-4">
                  <div>
                    <h5 className="fs-6 m-0" style={{ color: "#09051C", fontWeight: "600" }}>
                      {store.name}
                    </h5>
                    <div className="d-flex align-items-center">
                      {store?.ratings?.length > 0 ? (
                        <>
                          <span className="m all-resturant-rating">
                            {this.ratingCount(store.ratings, store.ratings.length)}
                          </span>{" "}
                          <AiFillStar className="me-2" style={{ color: "#FF9A62" }} size={11} />
                        </>
                      ) : (
                        <>
                          <span className="m all-resturant-rating">
                            {store?.rating}
                          </span>{" "}
                          <AiFillStar className="me-2" style={{ color: "#FF9A62" }} size={11} />
                        </>
                      )}
                      <span className="me-2" style={{ fontSize: "12px", color: "#3B3B3B" }}>
                        {store?.preparation_time ? `${store.preparation_time} Min - ` : ""}
                        {store?.delivery_radius ? `${store.delivery_radius} Km` : ""}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      className={store?.is_freedelivery === 1 ? "resturant-description" : "mb-4"}
                      style={{ color: "rgba(9, 5, 28, 0.25)", fontSize: "13px" }}
                    >
                      <Truncate lines={2}>{store?.description}</Truncate>
                    </div>
                    {store?.is_freedelivery === 1 && (
                      <div className="d-flex align-items-center">
                        <GiScooter style={{ color: "#FF9A62" }} size={20} />
                        <span className="all-resturant-delivery ms-2 mt-1">Free Delivery Upto...</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        // Show message if no stores found for the coupon
        <div className='pt-3 d-flex flex-column align-items-center mt-5'>
          <div className='mt-3 d-flex flex-column align-items-center'>
            {/* Optional placeholder image */}
          </div>
          <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Stores Found for this Coupon!!!</span>
        </div>
      )
    }
  </div>
) : (
  <div className='pt-3 d-flex flex-column align-items-center mt-5'>
    <div className='mt-3 d-flex flex-column align-items-center'>
      {/* Optional placeholder image */}
    </div>
    <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Coupons Found!!!</span>
  </div>
)}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    coupon: state.home.singlecoupon,
})

export default connect(mapStateToProps, { getSingleCoupon })(SingleCoupon)
