import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import AnyStore from "../components/Mobile/AnyStore";
import rootReducer from "./reducers";

// eslint-disable-next-line import/no-anonymous-default-export
export default (initialState) => {
    initialState = JSON.parse(window.localStorage.getItem("state")) || initialState;
    const middleware = [thunk];

    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware)
            // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );

    store.subscribe(() => {
        const state = store.getState();
        //save the config to localstorage
        // for (const setting in state.settings.settings) {
        // 	if (state.settings.settings.hasOwnProperty(setting)) {
        // 		const element = state.settings.settings[setting];
        // 		localStorage.setItem(element.key, element.value);
        // 	}
        // }

        // for (const translation in state.languages.language) {
        // 	if (state.languages.language.hasOwnProperty(translation)) {
        // 		const trans = state.languages.language[translation];
        // 		localStorage.setItem(translation, trans);
        // 	}
        // }

        const persist = {

            vendorOrder: state.vendorOrder,
            user: state.user,
            cart: state.cart,
            checkout: state.checkout,
            mobileitems: state.mobileitems,
            medicine: state.medicine,
            mobileCoupon: state.mobileCoupon,
            mobileOrder: state.mobileOrder,
            total: state.total,
            // common: state.common,
            // address: state.address,
            // loyalty: state.loyalty,
            // products: state.products,
            // home: state.home,
            // promotion: state.promotion,
            // settings: state.settings,
            // favourite: state.favourite,

            //vendor
            vendor: state.vendor,
            items: state.items,
            itemcategories: state.itemcategories,
            addoncategories: state.addoncategories,
            order: state.order,
            store: state.store,
            coupons: state.coupons,
            payouts: state.payouts,
            display_order: state.display_order,
            cards: state.cards,
            subscription: state.subscription,



            //customer
            home: state.home,
            mobilestorecategory: state.mobilestorecategory,
            notification: state.notification,
            singleStore:state.singleStore,
            anyStore:state.anystore
        };
        localStorage.setItem("state", JSON.stringify(persist));


        if (window.ReactNativeWebView) {
            const response = {
                type: 'state',
                data: persist
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.nativeApp){
            window.webkit.messageHandlers.nativeApp.postMessage(JSON.stringify({
                type: 'state',
                data: persist
            }));
        }
        // const user = {
        //     user: state.user
        // };
        // window.localStorage.setItem("user", JSON.stringify(user.user));
    });

    return store;
};
