export const SEND_OTP = "SEND_OTP";
export const LOGIN_OTP_USER = "LOGIN_OTP_USER";
export const LOGIN_OTP_USER_UPDATE = "LOGIN_OTP_USER_UPDATE";

export const REGISTER_USER = "REGISTER_USER";
export const CHECK_REFERRAL = "CHECK_REFERRAL";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_DEFAULT_ADDRESS = "GET_DEFAULT_ADDRESS";
export const SAVE_ADDRESS = "SAVE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_USER_WALLET = "GET_USER_WALLET";
export const UPDATE_USER_REDUX_INFO = "UPDATE_USER_REDUX_INFO";
export const DELETE_USER = "DELETE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
