import React, { Component } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { HiOutlineChevronLeft } from "react-icons/hi";
import OtpInput from "react-otp-input";
import HeadShake from "react-reveal/HeadShake";
import { connect } from "react-redux";
import {
    sendOtp,
    verifyOtp,
    registerUser,
    updateverifyOtp
} from "./../../../redux/mobile/user/action";
// const { user } = this.props;
export class Editotp extends Component {
    state = {
        loading: false,
        phone: "",
        name: "",
        email: "",
        email_exist: false,
        referral_code: "",
        otp_send: true,
        otp_enter: false,
        invalid_otp: false,
        ref_valid: false,
        ref_valid_message: "",
        newUser: false,
        Otp: "",
        login: true,
        referral_code: null,
    };

    // backToEnterNumber = () => {
    //     this.setState({
    //         otp_send: true,
    //         otp_enter: false,
    //         oldAcc: false,
    //         Otp: "",
    //     });
    // };
    // __handleInputOtp = (Otp) => {
    //     this.setState({ Otp });
    // };

    __handleInputOtp = (Otp) => {
        this.setState({ Otp });
    };
    starNumber = (num) => {
        let arr = Object.values(num);
        return (
            arr
                .splice(0, arr.length - 4)
                .fill("*")
                .join("") + arr.splice(-4).join("")
        );
    };
    __verifyOtp = () => {
        this.setState({ loading: true });

        // Fetch phone number and auth token
        const phone = localStorage.getItem("userPhone");
        const name = localStorage.getItem("userName");

        const email = localStorage.getItem("userEmail");

        const authToken = this.props.user ? this.props.user.data.id : null;

        // Ensure auth token is available before proceeding
        if (!authToken) {
            console.error("Auth token is missing!");
            this.setState({ loading: false });
            return;
        }
        // Verify OTP and pass phone, OTP, and auth token
        this.props
            .updateverifyOtp(phone,name,email, this.state.Otp, authToken)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    localStorage.setItem('modal', JSON.stringify(true));

                    // OTP verified, redirect to edit-profile page
                    this.props.history.push("/edit-profile");
                } else if (
                    response &&
                    response.payload.data === "DO NOT MATCH"
                ) {
                    this.setState({ loading: false, invalid_otp: true });
                }
            });
    };

    render() {

        return (
            <div>
                <React.Fragment>
                    <div className="w-100 mobile-add-pickup-bg">
                        <div
                            className="d-flex align-items-center position-fixed px-4 w-100 py-5"
                            style={{
                                top: "0px",
                                zIndex: "99999999",
                            }}
                        >
                            <div
                                className="px-2 pb-1 back-button"
                                onClick={() => this.props.history.push('/edit-profile')}
                            >
                                <span className="fs-2 back-button-icon">
                                    <HiOutlineChevronLeft
                                        style={{ color: "#DA6317" }}
                                    />
                                </span>
                            </div>

                            <div className=" mt-3 mobile-back-button-title fs-5">
                                <span className="mt-3">{this.props.title}</span>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-column px-4 w-100"
                            style={{ paddingTop: "18vw" }}
                        >
                            <div className="mobile-pickup-drop-heading">
                                Enter 4-digit Verification code
                            </div>
                            <div className="code mt-2">
                                OTP sent to +91{" "}
                                {localStorage.getItem("userPhone")}
                                {/* This OTP wil
                                            <br />
                                            expired in 01:30 */}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-center mt-5">
                        <div>
                            <OtpInput
                                value={this.state.Otp }
                                onChange={this.__handleInputOtp}
                                numInputs={4}
                                separator={
                                    <span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                }
                                isInputNum={true}
                                inputStyle={{
                                    height: "12vw",
                                    width: "12vw",
                                    border: ".01px solid #00000030",
                                    backgroundColor: "#ffffff",
                                    outline: "none",
                                    borderRadius: "10px",
                                    color: "#000",
                                    fontSize: "1.5em",
                                    fontWeight: "500",
                                }}
                                focusStyle={true}
                                shouldAutoFocus={true}
                                required
                            />
                        </div>
                    </div>
                    {this.state.invalid_otp && (
                        <HeadShake>
                            <div className="d-flex flex-row pt-2 justify-content-center mt-3">
                                <span className="text-danger text-center">
                                    <BsInfoCircle color={"red"} /> Invalid OTP.
                                    Pls try again
                                </span>
                            </div>
                        </HeadShake>
                    )}
                    <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                        <button
                            className="footer-button btn btn py-3 w-100"
                            style={{ border: "none" }}
                            type="button"
                            onClick={this.__verifyOtp}
                        >
                            <b style={{ color: "white" }}>Verify Otp</b>
                        </button>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userDetails: state.user.userDetails,
});

export default connect(mapStateToProps, {
    sendOtp,
    verifyOtp,
    registerUser,
    updateverifyOtp
})(Editotp);
